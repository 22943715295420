import {
    List,
    Toolbar,
    SaveButton,
    DeleteButton,
    Datagrid,
    TextField,
    BooleanField,
    DateField,
    SimpleForm,
    TextInput,
    BooleanInput,
    RadioButtonGroupInput,
    useRecordContext,
    ReferenceManyField,
    ReferenceField,
    useGetIdentity,
    UrlField
} from 'react-admin';
import { CreateInDialogButton, EditInDialogButton } from '@react-admin/ra-form-layout';
import { Link } from 'react-router-dom';

const statuses = [{id:'none',name:'resources.conversation_notes.statuses.none'},{id:'open',name:'resources.conversation_notes.statuses.open'},{id:'closed',name:'resources.conversation_notes.statuses.closed'}]

export const CustomEditToolbar = () => (
    <Toolbar sx={{ justifyContent: 'space-between' }}>
        <SaveButton />
        <DeleteButton redirect={false} />
    </Toolbar>
);
  

const AddNoteForm = () => {  
    const { data: identity, isLoading: identityLoading } = useGetIdentity(); 
    const record = useRecordContext(); // Gebruik `useRecordContext` hier correct
    return (
    <CreateInDialogButton fullWidth label="resources.conversation_notes.add_note" ButtonProps={{ variant: 'text' }} title="resources.conversation_notes.add_note">
        <SimpleForm
        defaultValues={{
            created_by: identity?.id, 
            conversation_id: record?.id, 
            }}        
        >
            <TextInput source="content" label="resources.conversation_notes.fields.content" required fullWidth multiline inputProps={{ style: { minHeight: '150px' } }} />
            <BooleanInput source="is_private" label="resources.conversation_notes.fields.is_private" />
            <RadioButtonGroupInput source="status" choices={statuses} defaultValue={'none'} label="resources.conversation_notes.fields.status" />
        </SimpleForm>
    </CreateInDialogButton>
    );
  };

const EditNoteButton = () => {  
    return (
    <EditInDialogButton fullWidth inline resource='conversation-notes' title="resources.conversation_notes.edit_note">
        <SimpleForm toolbar={<CustomEditToolbar/>}>
            <TextInput source="content" required fullWidth multiline inputProps={{ style: { minHeight: '150px' } }} label="resources.conversation_notes.fields.content" />
            <BooleanInput source="is_private" label="resources.conversation_notes.fields.is_private" />
            <RadioButtonGroupInput source="status" choices={statuses} defaultValue={'none'} label="resources.conversation_notes.fields.status" />
        </SimpleForm>
    </EditInDialogButton>
    );
  };  

export const ConversationsConversationNotesList = () => {
    const record = useRecordContext();    
    if (!record) {
        return null; // Of een placeholder/loading state
    }
    return (        
        <ReferenceManyField reference="conversation-notes" target="conversation_id" source="id">
            <AddNoteForm />
            <Datagrid bulkActionButtons={false} size="medium">
                <TextField source="content" label="resources.conversation_notes.fields.content" />
                <BooleanField source="is_private" label="resources.conversation_notes.fields.is_private" />
                <ReferenceField source="created_by" reference="users" link={false} label="resources.conversation_notes.fields.created_by" emptyText='resources.generic.unavailable'>
                    <TextField source="first_name" />
                </ReferenceField>
                <TextField source="status" label="resources.conversation_notes.fields.status" />
                <DateField source="created_at" label="resources.conversation_notes.fields.created_at" />
                <EditNoteButton/>
            </Datagrid>
        </ReferenceManyField>
    )
};

const ConversationNoteLinkField = ({ source }) => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <UrlField 
            source="content" 
            href={`#/conversations/${record.conversation_id}/show`}
            sx={{ 
                textDecoration: 'none',
            }}
        />
    );
};

const ConversationNotesListActionValue = (id:any, resource:any, record:any) => {
    return `/conversations/${record.conversation_id}/show`;
};

export const ConversationNotesList = () => {
    return (        
        <List resource='conversation-notes'>
            <Datagrid 
                bulkActionButtons={false} 
                size="medium"
                rowClick={ConversationNotesListActionValue}
            >
                <TextField source="content" label="resources.conversation_notes.fields.content" />
                <BooleanField source="is_private" label="resources.conversation_notes.fields.is_private" />
                <ReferenceField source="created_by" reference="users" link={false} label="resources.conversation_notes.fields.created_by">
                    <TextField source="first_name" />
                </ReferenceField>
                <TextField source="status" label="resources.conversation_notes.fields.status" />
                <DateField source="created_at" label="resources.conversation_notes.fields.created_at" />
            </Datagrid>
        </List>
    )
};

export default ConversationNotesList;