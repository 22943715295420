import React, { useState, useEffect } from 'react';
import { Chip, Autocomplete, TextField, IconButton, Grid, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNotify, useDataProvider, useTranslate, usePermissions } from 'react-admin';
import Emoji from 'react-emoji-render'; 

interface TagsManagerProps {
  record?: Record<string, any>;
  isEditable?: boolean;
  label?: string;
}

interface Tags {
  [key: string]: string;
}

// @todo add emoji-mart
const basicTags = {
  ':thumbs-up:': ':thumbs_up:',
  ':thumbs-down:': ':thumbs_down:',
};

const adminTags = {
  'needs-review': ':right-pointing_magnifying_glass:',
  'reviewed': ':check_mark_button:',
  'finetuning': ':wrench:',
  'example': ':sports_medal:',
};

const allTags = Object.assign({}, basicTags, adminTags)

const TagsManager: React.FC<TagsManagerProps> = ({ record, label, isEditable = true }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const { permissions } = usePermissions(); // Gebruik de usePermissions hook
  const [tags, setTags] = useState<string[]>(record?.tags || []);
  const [inputValue, setInputValue] = useState('');


  const tagList = ['admin', 'partner'].includes(permissions) ? Object.assign({}, basicTags, adminTags) : basicTags;
  
  const availableTags = Object.keys(tagList);

  useEffect(() => {
    if (record) {
      setTags((record.tags || []).filter(tag => tag in tagList));
    }
  }, [record]);

  const handleAddTag = (event: React.SyntheticEvent, newValue: string | null) => {
    if (newValue && !tags.includes(newValue)) {
      const updatedTags = [...tags, newValue];
      setTags(updatedTags);
      saveTags(updatedTags);
    }
  };

  const handleDeleteTag = (tagToDelete: string) => {
    const updatedTags = tags.filter(tag => tag !== tagToDelete);
    setTags(updatedTags);
    saveTags(updatedTags);
  };

  const saveTags = (updatedTags: string[]) => {
    if (record) {
      dataProvider.update('conversations', { id: record.id, data: { tags: updatedTags }, previousData: record })
        .then(() => {
          notify(`resources.conversations.tag_updated`, { type: 'success' });
        })
        .catch((error) => {
          notify(`Fout bij het bijwerken van tags: ${error.message}`, { type: 'error' });
        });
    }
  };


  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        {label && (
          <Typography component="p" variant="body1" className="RaLabeled-label css-bv1hqa-MuiTypography-root">
            <span>{label}</span>
          </Typography>
        )}
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
          {tags.map((tag, index) => (
            <Chip
              key={index}
              title={tag}
              label={<Emoji text={tagList[tag]} />}
              onDelete={isEditable ? () => handleDeleteTag(tag) : undefined}
              color="default"
              size="small"
              deleteIcon={<CancelIcon />}
              style={{ backgroundColor: '#eeeeee' }}
            />
          ))}
        </div>
      </Grid>
      {isEditable && (
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            value={null}
            onChange={(event, newValue) => {
              handleAddTag(event, newValue);
              setInputValue('');
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={availableTags}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label={translate('resources.conversations.add_tag')}
                variant="outlined"
                margin="dense"
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TagsManager;

export { allTags };